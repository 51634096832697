import React from "react"

class PrimierOffer extends React.Component {

  render() {
    return (

      <section className="process-of primier-off pt-100 mb-0">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
            <span className="heading-h3"><span ClassName="transNone">dApp</span> Development Services - <span className="bluecolor">Provided By</span> Our Company</span>
            </h2>
            <p className="text-center">
            With our top-notch <span ClassName="transNone">dApp</span> development service, we offer comprehensive solutions for creating decentralized applications, utilizing blockchain technology to enable secure, transparent, and autonomous functionalities.
            </p>
            <div className="d-lg-flex add-one">
              <div className="square" >
                <h3><span ClassName="transNone">dApp</span> Consulting</h3>
                <p className="pharagraph">
                We excel as pioneers in <span ClassName="transNone">dApp</span> development services, creating forward-looking strategies and engineering modules for future-proof solutions. Whether it's guiding tech stack selections or mapping out tech roadmaps, our digital transformation consulting sets new benchmarks for innovation.
                </p>
              </div>
              <div className="square">
                <h3>Custom <span ClassName="transNone">dApp</span> Development</h3>
                <p className="pharagraph">
                We are the creators of your imagination to customize your <span ClassName="transNone">dApp</span> development. Approach our <span ClassName="transNone">dApp</span> development team for DAO-enabled DeFi systems, <span ClassName="transNone">dApp</span> architectural design, User-engaging Gamified systems, Smart Contract Integration, and so on. Our team tailors the decentralized app development to your perspective.
                </p>
              </div>
            </div>
            <div className="d-lg-flex add-two mb-3">
              <div className="square" >
                <h3>Integration & Porting</h3>
                <p className="pharagraph">
                From SDK and API integration to ERP, CX to CRM, AI Modules to BI, ETL, and beyond, we've got you covered with <span ClassName="transNone">dApp</span> developments! Our robust deployment ensures your decentralized application runs with top-notch software tools and data governance. Let's make your <span ClassName="transNone">dApp</span> dreams a reality!
                </p>
              </div>
              <div className="square" >
                <h3>Smart Contract Development</h3>
                <p className="pharagraph">
                We develop a robust code for your <span ClassName="transNone">dApp</span>, a bug-free smart contract coding that facilitates operations efficiently. We code to innovate and program to inspire your <span ClassName="transNone">dApp</span> business. Our created code will transform secure communication, and process the transaction easily for the users.
                </p>
              </div>
            </div>
            <a href="/contact-us/" className="bluebtn m-auto d-table">Free Trial</a>
          </div>
      </section>

    )
  }
}

export default PrimierOffer
