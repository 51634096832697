import React, { Component } from 'react'


class CoreFeatures extends Component {

  render() {
    return (
      <section className="dapps pt-100 mb-0">
        <div className='gray-bg'>
        <div className="container">
            <h2 className="heading-h2 text-center"><span ClassName="transNone">dApp</span> <span className="bluecolor">Development Process </span>Followed By Our Company</h2>
            <p className='text-center'>As a <span ClassName="transNone">dApp</span> development company, Coinsclone offers end-to-end  <span ClassName="transNone">dApp</span> development from Blockchain ideation to deployment, facilitated by a dedicated team of blockchain experts.</p>
          <div className="row mb-3">
          <div className='d-flex flex-wrap justify-content-center px-0'>
                        <div className='fea-card'>
                            <h3>Blockchain Ideation</h3>
                            <p className='pharagraph'>We evaluate, brainstorm, formulate, and prioritize based on your perspective. Our <span ClassName="transNone">dApp</span> formulates use cases like "Fail Fast", "Minimum Viable Product (MVP)", and "Version 2.0" to address different levels of complexity. We Identify business entities, transactions, data managed on-chain and off-chain, technical architecture and components, and develop a roadmap that outlines your key milestones.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Proof of Concept Development</h3>
                            <p className='pharagraph'>We demonstrate the feasibility and practicality of the blockchain through PoC. We build a <span ClassName="transNone">dApp</span> that validates your concept, tests key functionalities, and provides valuable insights for further development. Our well-executed PoC development can help mitigate risks, validate assumptions, and enhance the likelihood of success for your <span ClassName="transNone">dApp</span> venture.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Visual & Technical Design</h3>
                            <p className='pharagraph'>We as a <span ClassName="transNone">dApp</span> development company create a user interface design for each software component. The development of our <span ClassName="transNone">dApp</span> encompasses both visual and technical aspects. Our <span ClassName="transNone">dApp</span> experts analyze the scalability requirements and tailor an efficient UX for the decentralized application. Working together with technical architects and visual designers, we establish the need for an ideal decentralized application.</p>
                        </div>
                        <div className='fea-card'>
                            <h3><span ClassName="transNone">dApp</span> Development</h3>
                            <p className='pharagraph'><span ClassName="transNone">dApp</span> development involves pre-alpha, beta, candidate release, and final production stages. This development covers the process from the early stages of ideation, backend and frontend implementation, exclusive performance testing, and refinement, ultimately resulting in the launch of a production-ready <span ClassName="transNone">dApp</span>. Each stage entails specific operations and milestones that contribute to the thriving development of your <span ClassName="transNone">dApp</span>.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Deployment</h3>
                            <p className='pharagraph'>Once deployed, the <span ClassName="transNone">dApp</span> becomes publicly accessible for immediate use. The Deployment phase in <span ClassName="transNone">dApp</span> development involves provisioning resources, deploying smart contracts on the main chain, considering hybrid solutions, deploying on cloud infrastructure, and submission of mobile applications to app stores. We as a top-tier dapp development company,  ensure the <span ClassName="transNone">dApp</span> is made available to users through various channels and platforms.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Upgrades </h3>
                            <p className='pharagraph'>This phase focuses on launching marketing campaigns, promoting the developed <span ClassName="transNone">dApp</span> to potential users, and providing support to the users who engage with the application. The development team continues to monitor the <span ClassName="transNone">dApp</span>'s performance, address any emerging issues, and strategize future updates and enhancements to optimize the user experience further.</p>
                        </div>
                    </div>
          </div>
          <a href="/contact-us/" className="bluebtn m-auto d-table">Free Trial</a>
        </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures