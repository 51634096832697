import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="main-heading text-center">Frequently Asked Questions?</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What programming language is used for <span ClassName="transNone">dApp</span> Development?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">The most common programming languages used for <span ClassName="transNone">dApp</span> development are Solidity, Vyper, Javascript, Python, Go, and Rust. Several programming languages can be used for <span ClassName="transNone">dApp</span> development, depending on the blockchain platform being utilized.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                Which is the best platform to build a <span ClassName="transNone">dApp</span>?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">To build a <span ClassName="transNone">dApp</span>, some of the leading platform options are Ethereum, EOS, Tron, Binance Smart Chain, Solana, Avalanche, etc. The choice of platform depends on the specific requirements and goals of your <span ClassName="transNone">dApp</span> project, as well as factors such as developer expertise and technical support.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                How to integrate <span ClassName="transNone">dApp</span> with existing platforms?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">To integrate <span ClassName="transNone">dApp</span> with the existing platform, you need to integrate smart contracts through web3. If the current platforms provide APIs (Application Programming Interfaces), then API is also integrated. In order to provide the <span ClassName="transNone">dApp</span> with the required functionality and data, developers might have to establish API endpoints.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                How much does it cost to develop a <span ClassName="transNone">dApp</span>?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The cost to develop a <span ClassName="transNone">dApp</span> on average would typically range between <b>$10,000 and $25,000</b>.  However, it is difficult to provide an exact cost estimate for <span ClassName="transNone">dApp</span> development without considering the specific needs and conditions of the project. Each project's cost may differ somewhat depending on customization and add-on features.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                How long does it typically take to develop a <span ClassName="transNone">dApp</span>?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">To develop a <span ClassName="transNone">dApp</span>, it will certainly take <b>4 to 6 months</b> of duration. But it's not the precise amount of time. The duration could change based on how complicated your <span ClassName="transNone">dApp</span> project is.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection