import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2">What Makes Coinsclone The Best <span className="bluecolor"><span ClassName="transNone">dApp</span> Development Company?</span></h2>
              <p className='text-center pharagraph'>
              Coinsclone, a reputable <span ClassName="transNone">dApp</span> development company, is renowned for its <span ClassName="transNone">dApp</span> development solutions where a team of highly qualified developers specializes in blockchain technology, smart contract development, and more. We have ample remarkable <span ClassName="transNone">dApp</span> project records and convinced clients, which serve as evidence of our decentralized app development company.
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 left-side" >
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/dapp/best-dapp-development-company.webp"
                alt="best dapp development company"
                placeholder='none'
                width={565}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph">As a <span ClassName="transNone">dApp</span> development company, we offer highly customizable solutions bespoke to meet your expectations and requirements. Our experts will closely analyze your needs and provide revenue-yielding solutions that align with your <span ClassName="transNone">dApp</span> business. With Coinsclone as your <span ClassName="transNone">dApp</span> development company, you're supported every step of the way, from concept to execution. Leveraging the forefront of blockchain tech, we stay ahead of the curve to give you a competitive edge. Experience transparent pricing and reliable delivery with Coinsclone – your trusted <span ClassName="transNone">dApp</span> development partner!
              </p>
              <ul>
                <li>Well-versed set of  <span ClassName="transNone">dApp</span> Developers</li>
                <li>50+ well-trained blockchain professionals.</li>
                <li>Enables as a prompt <span ClassName="transNone">dApp</span> development company</li>
                <li>Complete Package at Affordable Cost</li>
                <li>Scalability</li>
                <li>Instant Issue Management</li>
                <li>24*7 Technical and Customer Support.</li>
                <li>Quickest project completion.</li>
              </ul>
              <p className="pharagraph mb-0">Think smart, Build smart with us!</p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose